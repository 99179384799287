import {
    createCookie,
    eraseCookie,
    readCookie,
} from './../helpers/cookies-helper';
const switcherID = 'js-select-loacalization';
const switcher = document.getElementById(switcherID);

if (switcher) {
    switcher.addEventListener('change', e => {
        let localization = switcher.value;
        let cookie = readCookie('user_localization');

        location_switcher_cookie(cookie, localization);
    });
}

const switcherLink = document.querySelectorAll('.js-select-link');
switcherLink.forEach(item => {
    item.addEventListener('click', e => {
        e.preventDefault();
        let localization = item.getAttribute('data-cookie');
        let cookie = readCookie('user_localization');

        location_switcher_cookie(cookie, localization);
    });
});

function location_switcher_cookie(cookie, localization) {
    if (cookie) {
        eraseCookie('user_localization');
        createCookie('user_localization', localization, 120);
    } else {
        createCookie('user_localization', localization, 120);
    }
    createCookie('cookie_user_changed', 1, 120);

    // window.location.reload(true);
    window.location.href = home_url;
}

// let cookieChange = readCookie('cookie_user_changed');
// if (cookieChange == 1) {
//     window.location.href = home_url;
//     eraseCookie('cookie_user_changed');
// }
